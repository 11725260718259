export const state = () => ({
  profile: {},
  rewards: [],
  rewardList: []
})
export const mutations = {
  setProfile(state, items) {
    state.profile = items
  },
  setRewardList(state, items) {
    state.rewardList = items.records
  },
}
export const actions = {
  fetchProfile: async function ({ commit }) {
    const { data } = await this.$axios.get('user/me')
    commit('setProfile', data.data)
  },
  fetchRewardList: async function ({ commit }) {
    const { data } = await this.$axios.get('userReward/list')
    commit('setRewardList', data.data)
  },
}
