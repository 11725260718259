<template>
  <div id="error-page" class="container">
    <div v-if="error.statusCode === 404" class="error-content">
      <h2>{{ t("error.oops") }}</h2>
      <p>{{ t("error.apologise_not_exist") }}</p>
      <br>
      <div class="row">
        <div class="col-12">
          {{ t("error.tips") }}
          <p>
          <a href="/">{{ t("error.go_homepage") }}</a>
          </p>
        </div>
      </div>
    </div>
    <div v-else class="error-content">
      <h2>
        {{ t('error.title') }}
      </h2>
      <p>
        {{ t('error.desc') }}
      </p>
      <p>{{ t('error.tips') }}</p>
      <p><a href="/">{{t('error.go_homepage')}}</a>.</p>
      <p v-html="t('error.contact')"/></p><hr>
      <div class="row">
        <div class="col col-md-4">
          <strong>{{ t('error.uri') }}</strong>
        </div>
        <div class="col col-md-8">
          <a :href="url">{{ url }}</a>
        </div>
      </div>
      <div class="row">
        <div class="col col-md-4">
          <strong>{{ t('error.message') }}</strong>
        </div>
        <no-ssr>
          <div class="col col-md-8">
            {{ error.statusCode }} {{ error.message || "Server Error" }}
          </div>
        </no-ssr>
        <br>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    error: {
      type: Object,
      default: () => ({ statusCode: 999, message: 'UnknownError' })
    }
  },
  data: function() {
    return {
      url: '',
      cpHost: process.env.cp_host
    }
  },
  async asyncData({ store }) {
  },
  mounted() {
    this.url = location.href || ''
    let reqUrl = location.search.split('req=')[1] || ''
    if (location.protocol === 'http:') {
      // Handle localhost
      reqUrl = reqUrl.replace('https', 'http')
    }
    if (window.history.replaceState && reqUrl !== '') {
      // prevents browser from storing history with each change:
      try {
        window.history.replaceState('', '', decodeURIComponent(reqUrl))
      } catch (e) {
        console.error('ReplaceStateError:' + e)
      }
      this.url = decodeURIComponent(reqUrl)
      this.error.message = 'Server Error'
      this.error.statusCode = 500
    }
  },
  head() {
    return {
      title: 'Error'
    }
  }
}
</script>
<style lang="scss">
#error-page {
  padding-top: 50px;
  margin: 0px auto;
  padding-bottom:50px;
  a{
    color:#4d947f!important;
  }
}
.error-content {
  max-width: 1200px;
  background: #fff;
  margin: 0px auto;
  padding: 20px;
  text-align: center;
}
.error-content h2 {
  font-weight: 700;
  font-size: 35px;
  margin: 20px;
}
.error-content p {
  margin: 8px 0px;
}
.error-content .col-md-8 {
  text-align: left;
}
.error-content .col-md-4 {
  text-align: right;
}
@media screen and (max-width: 1000px) {
  .error-content .col-md-4,
  .error-content .col-md-8 {
    width: 100% !important;
    max-width: 100%;
    flex: none;
    text-align: left;
  }
}
</style>
