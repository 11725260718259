export const state = () => ({
  list: [],
  typeList: [],
})
export const mutations = {
  setList(state, items) {
    state.list = items
  },
  setTypeList(state, items) {
    state.typeList = items
  },
}
export const actions = {
  fetchList: async function ({ commit }) {
    const { data } = await this.$axios.post('/query/list')
    commit('setList', data.data)
  },
  fetchListByType: async function ({ commit }, type) {
    const { data } = await this.$axios.get('/query/getByType?type=' + type)
    commit('setTypeList', data.data)
  },
}
