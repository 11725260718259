export const state = () => ({
  products: {},
  countries: [],
  industries: [],
  paymentMethods: [],
  incomeProofTypes: [],
  loanCalculateMethods: [],
  employmentTypes: [],
  insuranceExtraFeatures: [],
  carFactories: [],
  carModels: []
})
export const mutations = {
  setProducts(state, item) {
    state.products = item
  },
  setCountries(state, item) {
    state.countries = item
  },
  setIncomeProofTypes(state, item) {
    state.incomeProofTypes = item
  },
  setPaymentMethods(state, item) {
    state.paymentMethods = item
  },
  setIndustries(state, item) {
    state.industries = item
  },
  setPropertyStatus(state, item) {
    state.propertyStatus = item
  },
  setLoanCalculateMethods(state, item) {
    state.loanCalculateMethods = item
  },
  setEmploymentTypes(state, item) {
    state.employmentTypes = item
  },
  setInsuranceExtraFeatures(state, item) {
    state.insuranceExtraFeatures = item
  },
  setCarFactories(state, item) {
    state.carFactories = item
  },
  setCarModels(state, item) {
    state.carModels = item
  }
}
export const actions = {
  fetchProduct: async function ({ commit }) {
    const { data } = await this.$axios.get('/option/products')
    commit('setProducts', data.data || [])
  },
  fetchCountry: async function ({ commit }) {
    const { data } = await this.$axios.get('/option/countries')
    commit('setCountries', data.data || [])
  },
  fetchIncomeProofType: async function ({ commit }) {
    const { data } = await this.$axios.get('/option/incomeProofTypes')
    commit('setIncomeProofTypes', data.data || [])
  },
  fetchEmploymentType: async function ({ commit }) {
    const { data } = await this.$axios.get('/option/employmentTypes')
    commit('setEmploymentTypes', data.data || [])
  },
  fetchPaymentMethod: async function ({ commit }) {
    const { data } = await this.$axios.get('/option/paymentMethods')
    commit('setPaymentMethods', data.data || [])
  },
  fetchIndustry: async function ({ commit }) {
    const { data } = await this.$axios.get('/option/industries')
    commit('setIndustries', data.data || [])
  },
  fetchPropertyStatus: async function ({ commit }) {
    const { data } = await this.$axios.get('/option/propertyStatus')
    commit('setPropertyStatus', data.data || [])
  },
  fetchLoanCalculateMethod: async function ({ commit }) {
    const { data } = await this.$axios.get('/option/loanCalculateMethods')
    commit('setLoanCalculateMethods', data.data || [])
  },
  fetchInsuranceExtraFeature: async function ({ commit }) {
    const { data } = await this.$axios.get('/option/insuranceExtraFeatures')
    commit('setInsuranceExtraFeatures', data.data || [])
  },
  fetchCarFactory: async function ({ commit }) {
    const { data } = await this.$axios.get('/option/carFactories')
    commit('setCarFactories', data.data || [])
  },
  fetchCarModel: async function ({ commit }, payload) {
    const carFactory = payload.carFactory || ''
    const { data } = await this.$axios.get('/option/carModelByCarFactory?carFactory=' + carFactory)
    commit('setCarModels', data.data || [])
  },
}
