export const state = () => ({
  detail: {},
  form: {},
  defaultData: {}
})
export const mutations = {
  setDetail(state, item) {
    state.detail = item
  },
  setForm(state, item) {
    state.form = item
  },
  setDefaultData(state, item) {
    state.defaultData = item
  },
}
export const actions = {
  fetchDetail: async function ({ commit }, path) {
    const param = { path: path }
    const { data } = await this.$axios.post('/page/getByPath', param)
    commit('setDetail', data.data)
  },
  fetchPrefill: async function ({ commit }, id) {
    const { data } = await this.$axios.get('/form/prefill/' + id)
    console.log(data)
    commit('setDefaultData', data.data)
  },
  fetchForm: async function ({ commit }, id) {
    const { data } = await this.$axios.get('/form/' + id)
    commit('setForm', data !== undefined ? data.data : undefined)
  },
}
