export default function ({ app }) {
  app.router.afterEach((to, from, next) => {
    const parseCookie = str =>
      str
        .split(';')
        .map(v => v.split('='))
        .reduce((acc, v) => {
          if (v[0] !== undefined && v[1] !== undefined) {
            acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(
              v[1].trim()
            )
          }
          return acc
        }, {})
    let locale = 'zh'
    const acceptLangs = ['zh', 'en']
    if (from.query.from === 'app') {
      return
    }
    if (acceptLangs.includes(from.query.lang)) {
      locale = from.query.lang
    } else if (!from.name && acceptLangs.includes(to.query.lang)) {
      locale = to.query.lang
    } else if (process.browser) {
      locale = parseCookie(document.cookie).locale
    }
    const queryParams = { lang: locale };
    const updatedQuery = { ...to.query, ...queryParams };
    app.router.replace({ ...to, query: updatedQuery });
  });
}
