<template>
  <div :class="[page ? `page-${page}` : '', 'lang-' + lang]">
    <Header />
    <Nuxt />
    <Footer />
  </div>
</template>
<script>
import SEO from "./seo";
import Footer from "~/components/layout/Footer";
import Header from "~/components/layout/Header";

export default {
  components: {
    Header,
    Footer,
  },
  data: function () {
    const locale = this.getLocale();
    return {
      page: "",
      lang: locale,
    };
  },
  head() {
    return {
      ...SEO.head,
      title: this.t("common.page_title"),
    };
  },
};
</script>
