export const state = () => ({
  vendorToken: null
})
export const mutations = {
  setVendorToken(state, token) {
    state.vendorToken = token
  }
}
export const actions = {
  saveVendorToken: function ({ commit }, token) {
    commit('setVendorToken', token)
  }
}