import commonFunc from "@/assets/js/commonHelper";
export const state = () => ({
  checkIdList: {},
})
export const mutations = {
  setCheckIdList(state, item) {
    state.checkIdList = item
  },
}
export const actions = {
  fetchCheckIdList: async function ({ commit }) {
    const { data } = await this.$axios.get('/checkId/list', { headers: commonFunc.getBearTokenHeader("vendor_token") });
    if (data.success) {
      commit('setCheckIdList', data?.data)
    } else {
      commit('setCheckIdList', [])
    }
  },
}
