export const state = () => ({
  list: [],
  detail: [],
  types: []
})
export const mutations = {
  setList(state, items) {
    state.list = items
  },
  setDetail(state, items) {
    state.detail = items
  },
  setTypes(state, items) {
    state.types = items
  }
}
export const actions = {
  fetchList: async function ({ commit }, payload) {
    const { data } = await this.$axios.post('/blog/getByType', payload)
    commit('setList', data.data)
  },
  fetchDetail: async function ({ commit }, id) {
    const { data } = await this.$axios.get('/blog/' + id)
    commit('setDetail', data.data)
  },
  fetchType: async function ({ commit }, id) {
    const { data } = await this.$axios.get('/blog/getTypes')
    commit('setTypes', data.data)
  }
}
