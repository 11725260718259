<template>
  <footer>
    <div class="container-fluid main">
      <h3>{{ t("footer.title1") }}</h3>
      <p v-html="t('footer.title2')" />
      <Apps />
    </div>
    <div class="sub">
      <div class="container-fluid">
        <div class="row">
        <div class="col-6 col-lg-2">
          <ul>
            <h4>{{ t('footer.service') }}</h4>
            <router-link
              v-for="(menu, index) in $store.state.layout.menu"
              :key="index"
              :to="menu.path"
            >
              <li>{{ t('menu.' + menu.path.replace('/','')) }}</li>
            </router-link>
          </ul>
        </div>
        <div class="col-6 col-lg-2">
          <ul>
            <h4>{{ t('footer.about') }}</h4>
            <router-link
              v-for="(item, index) in links"
              :key="index"
              :to="item.link"
            >
              <li>{{ t('footer.'+item.key) }}</li>
            </router-link>
          </ul>
        </div>
        </div>
        <div class="row">
          <div class="col-6  col-md-6 col-xs-12">
            <Apps />
          </div>
          <div class="col-6  col-md-6 col-xs-12 align-self-center social-link">
            <a
              v-for="(item, index) in socialLinks"
              :key="index"
              :href="item.link"
              target="_blank"
            >
              <img :src="item.imgUrl" :alt="item.alt" >
            </a>
          </div>
        </div>
        <div class="info row">
          <div class="col-lg-11">
            {{ t('footer.info') }}
          </div>
        </div>
        <div class="copyright">
          {{ t('footer.copyright',{year:new Date().getFullYear()}) }}
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import Apps from "~/components/layout/Apps";
export default {
  components: {
    Apps,
  },
  data: function () {
    return {
      links: [
        { key: "intro", link: "/" },
        { key: "contact", link: "/#contact-us" },
        { key: "policy", link: "/privacy-policy" },
        { key: "partner", link: "/partner" },
        { key: "tnc", link: "/terms-conditions" },
        { key: "qa", link: "/query" },
      ],
      socialLinks: [
        {
          link: "mailto:info@moneyplaza.com.hk",
          imgUrl: "/img/icons/icon-email.png",
          alt: "Email",
        },
        {
          link: "https://wa.me/85261820861",
          imgUrl: "/img/icons/icon-whatsapp.png",
          alt: "Whatsapp",
        },
        {
          link: "https://youtube.com/@moneyplazahongkong",
          imgUrl: "/img/icons/icon-youtube.png",
          alt: "Youtube",
        },
        {
          link: "https://www.facebook.com/moneyplazahk",
          imgUrl: "/img/icons/icon-facebook.png",
          alt: "Facebook",
        },
        {
          link: "https://www.instagram.com/moneyplazahk",
          imgUrl: "/img/icons/icon-instagram.png",
          alt: "Instagram",
        },
      ],
    };
  },
};
</script>
