
import https from 'https'
import commonFunc from '~/assets/js/commonHelper.js'

export default function (ctx) {
  // const isDev = process.env.NODE_ENV === 'development'
  // const isDev = true

  const { $axios, redirect, app } = ctx
  const getLanguage = function () {
    const locale = app.$cookies.get('locale') !== undefined ? app.$cookies.get('locale') : 'zh'
    const mapping = {
      en: 'en-US',
      zh: 'zh-HK'
    }
    return mapping[locale] !== undefined ? mapping[locale] : 'zh-HK'
  }
  const authPathes = ['profile', 'user', 'auth/logout', 'reward', 'user/verifyMobile', 'user/verifyEmail', 'userReward/list'];
  const vendorPathes = ['checkId/'];
  // const vendorPathes = ['checkId/list', 'checkId/submit', 'checkId/formDetails', 'checkId/addcomment', 'checkId/comment', 'checkId/viewComment', 'checkId/deleteComment', 'additionalForm/get']
  const noLangPathes = ['/lender/getLenders', '/company/getCompaniesByType']
  $axios.onRequest((config) => {
    const header = config.headers
    const path = config.url.replace(/\?.*/, '');

    if (noLangPathes.includes(path)) {
      delete header['Accept-Language']
    } else {
      header['Accept-Language'] = getLanguage()
    }

    config.baseURL = process.env.BASE_API
    if (process.client) {
      config.baseURL = process.env.BASE_API_URL
    }
    const agent = new https.Agent({
      rejectUnauthorized: false
    })
    config.httpsAgent = agent;

    // if (config.url.indexOf('/checkId/') !== -1 || config.url.indexOf('/lender/') !== -1) {
    //   config.baseURL = process.env.FORM_API_URL
    // }
    if (config.url.indexOf('/borrower/') !== -1) {
      config.baseURL = process.env.CMS_API_URL
    }
    if (process.client && commonFunc.getLocalStorage('access_token')) {
      authPathes.forEach((path) => {
        if (config.url.indexOf(path) !== -1) {
          header.Authorization = 'Bearer ' + commonFunc.getLocalStorage('access_token')
          return false;
        }
      });
    }
    const accessToken = app.$cookies.get('vendor_token');
    if (accessToken) {
      vendorPathes.forEach((path) => {
        if (config.url.indexOf(path) !== -1) {
          header.Authorization = 'Bearer ' + JSON.parse(accessToken.value).token
          return false;
        }
      });
    }
  })
  $axios.onError(function (error) {
    const code = parseInt(error.response && error.response.status)
    const response = error.response;
    if (response === undefined) {
      return Promise.reject(error)
    }
    if (process.client) {
      if (code === 401) {
        if (window.location.href.indexOf('vendor') !== -1) {
          window.location.href = '/vendor/login'
          return;
        }
        // window.location.href = '/login'
      }
      if (code === 406) {
        throw new Error(response.data.detail)
      }
      if (code === 404) {
        window.location.href = '/notfound'
      }
      throw new Error(response)
      // const lang = app.$cookies.get('i18n_redirected') // Get language
      // console.log('Client Redirect', { response, app, store })
      // app.router.push(
      //   {
      //     name: 'notFound___' + lang,
      //     params: { errorCode: '502', response: response }
      //   }
      // )
    } else {
      // throw new Error(response.data.detail)
      const domain = ctx.req.headers.host.indexOf('localhost') !== -1 ? ctx.req.protocol + '://' + ctx.req.headers.host : process.env.HOST;
      const url = process.server ? domain + ctx.req._parsedOriginalUrl.href : window.location.href
      if (code === 401) {
        // TODO for vendor API return 401, redirect to vendor login page
        redirect('/vendor/login?redirect_uri=' + encodeURIComponent(url));
      } else {
        redirect('/error?req=' + encodeURIComponent(url))
      }
      return Promise.reject(error)
    }
  })
  // $axios.onResponse((response) => {
  //   // special handing for 200 response with empty response body
  //   if (response.data.code !== '10000') {
  //     if (!process.client) {
  //       redirect('/notfound')
  //       // return new Error(response.data.message)
  //     }
  //   }
  // })
  // const throwError = function (error) {
  //   // if (isDev) {
  //   // console.log('axios Error', { response })
  //   // }
  //   // console.log(response)

  // }
}
