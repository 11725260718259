import commonFunc from "@/assets/js/commonHelper";
export const state = () => ({
  detail: {},
  vendor: {},
  offer: {},
  offerStatus: '',
  lender: {},
  approveResult: {},
  secondApproveResult: {},
  commentResult: {},
  interviewResult: {},
  applicantInfo: {},
  surveyCode: '',
  survey: {},
  checkIdList: [],
  auth: {},
  confirmContactTime: {},
  cancelResult: {},
  isEmailVerified: false,
  isMobileVerified: false,
  appliedProductInfo: [],
  vendors: [],
  appliedVendors: [],
  currentVendors: [],
  company: [],
  additionalInfo: {},
})

export const mutations = {
  setDetail(state, borrowerInfo) {
    state.detail = borrowerInfo
    const applicantInfo = {}
    if (Array.isArray(borrowerInfo) && borrowerInfo.length > 0) {
      borrowerInfo.forEach((item) => {
        applicantInfo[item.name] =
          item.value !== undefined && item.value !== "" ? item.value : "N/A";
      });
    }
    state.applicantInfo = applicantInfo
  },
  setAuth(state, item) {
    state.auth = item
  },
  setAdditionalInfo(state, item) {
    state.additionalInfo = item
  },
  setConfirmContactTime(state, item) {
    state.confirmContactTime = item
  },
  setVendor(state, item) {
    state.vendor = item
  },
  setCompany(state, item) {
    state.company = item
  },
  setLender(state, item) {
    state.lender = item
  },
  setAppliedVendors(state, item) {
    state.appliedVendors = item
  },
  setCurrentVendors(state, item) {
    state.currentVendors = item
  },
  setVendors(state, item) {
    state.vendors = item
  },
  setOffer(state, item) {
    state.offer = item
  },
  setOfferStatus(state, item) {
    state.offerStatus = item
  },
  setSurveyCode(state, item) {
    state.survey = item
    state.surveyCode = item?.code || ''
  },
  setApproveResult(state, item) {
    state.approveResult = item
  },
  setInterviewResult(state, item) {
    state.interviewResult = item
  },
  setCommentResult(state, item) {
    state.commentResult = item
  },
  setCancelResult(state, item) {
    state.cancelResult = item
  },
  setCheckIdList(state, item) {
    state.checkIdList = item
  },
  setSecondApproveResult(state, item) {
    state.secondApproveResult = item
  },
  setMobileVerify(state, item) {
    state.isMobileVerified = item
  },
  setEmailVerify(state, item) {
    state.isEmailVerified = item
  },
  setAppliedProductInfo(state, item) {
    state.appliedProductInfo = item
  },
}
export const actions = {
  fetchDetail: async function ({ commit }, hkid) {
    try {
      const { data } = await this.$axios.get('/checkId/formDetails?hkid=' + hkid, { headers: commonFunc.getBearTokenHeader("vendor_token") })
      // let totalAmount = 0
      // let totalTenor = 0
      // const result = []
      console.log(data);
      if (data?.formInfo) {
        const result = [];
        let remainingLoans = 0;
        let remainingLoanAmount = 0;
        for (const key in data?.formInfo) {
          result.push({ name: key, value: data?.formInfo[key] })
          if (['bankLoan', 'firstTierLenderLoan', 'secondTierLenderLoan'].includes(key)) {
            remainingLoans += data?.formInfo[key]?.count || 0;
            remainingLoanAmount += data?.formInfo[key]?.amount || 0;
          }
        }
        result.push({ name: 'hasLoan', value: remainingLoans > 0 })
        result.push({ name: 'remainingLoans', value: remainingLoans })
        result.push({ name: 'remainingLoanAmount', value: remainingLoanAmount })
        commit('setDetail', result)
      } else {
        const { data } = await this.$axios.get('/checkId/details?hkid=' + hkid, { headers: commonFunc.getBearTokenHeader("vendor_token") })
        commit('setDetail', data)
      }
    } catch (e) {
      console.error(e);
    }
  },
  fetchVendor: async function ({ commit }, params) {
    const paramStr = new URLSearchParams(params).toString();
    try {
      const { data } = await this.$axios.get('/checkId/info?' + paramStr, { headers: commonFunc.getBearTokenHeader("vendor_token") })
      commit('setVendor', data)
    } catch (e) {
      console.error(e);
    }
  },
  fetchAdditionalInfo: async function ({ commit }, hkid) {
    try {
      const { data } = await this.$axios.get('/additionalForm/get?hkid=' + hkid)
      commit('setAdditionalInfo', data?.data || {})
    } catch (e) {
      console.error(e);
    }
  },
  fetchOffer: async function ({ commit }, params) {
    const paramStr = new URLSearchParams(params).toString();
    try {
      const { data } = await this.$axios.get('/checkId/offers?' + paramStr, { headers: commonFunc.getBearTokenHeader("vendor_token") })
      commit('setOffer', data)
    } catch (e) {
      console.error(e);
    }
  },
  fetchOfferStatus: async function ({ commit }, params) {
    const paramStr = new URLSearchParams(params).toString();
    try {
      const { data } = await this.$axios.get('/checkId/getOfferStatus?' + paramStr, { headers: commonFunc.getBearTokenHeader("vendor_token") })
      commit('setOfferStatus', data)
    } catch (e) {
      console.error(e);
    }
  },
  fetchCheckIdList: async function ({ commit }) {
    try {
      const { data } = await this.$axios.get('/checkId/list', { headers: commonFunc.getBearTokenHeader("vendor_token") })
      commit('setCheckIdList', data)
    } catch (e) {
      console.error(e);
    }
  },
  fetchCompany: async function ({ commit }, companyId) {
    const type = 'loan,mortgate'
    if (!companyId) {
      const { data } = await this.$axios.get('/company/getCompaniesByType?type=' + type)
      commit('setCompany', data?.data || [])
    } else {
      // const paramStr = new URLSearchParams(params).toString();
      const { data } = await this.$axios.get('/company/getCompaniesByType?type=' + type)
      const companies = data?.data
      let company = {}
      companies.forEach((item) => {
        if (item.id === parseInt(companyId)) {
          company = item
        }
      })
      commit('setCompany', company)
    }
  },
  fetchLender: async function ({ commit }) {
    const vendorList = []
    const { data } = await this.$axios.get('/lender/getLenders')
    data.data.forEach((item) => {
      vendorList.push(item)
    })
    commit('setLender', vendorList)
  },
  fetchAppliedVendors: async function ({ commit }) {
    const vendorList = []
    const { data } = await this.$axios.get('/lender/getAppliedLenders')
    data.data.forEach((item) => {
      vendorList.push(item)
    })
    commit('setAppliedVendors', vendorList)
  },
  fetchCurrentVendors: async function ({ commit }) {
    const vendorList = []
    const { data } = await this.$axios.get('/lender/getCurrentLenders')
    data.data.forEach((item) => {
      vendorList.push(item)
    })
    commit('setCurrentVendors', vendorList)
  },
  fetchVendors: async function ({ commit }, type) {
    let formId = ""
    const vendorList = []
    if (type === "query" || type === "contract") {
      const path = type === "query" ? '/company/getCompaniesForQueryOption' : '/company/getCompaniesForContractOption'
      const { data } = await this.$axios.get(path)
      data.data.forEach((item) => {
        if (companyIds.indexOf(item.id) > -1) {
          vendorList.push(item)
        }
      })
      commit('setVendors', vendorList)
      return;
    }
    switch (type) {
      case "personal_loan":
        formId = process.env.FORM_PERSONAL_LOAN_ID;
        break;
      case "owner_private_loan":
        formId = process.env.FORM_OWNER_PRIVATE_LOAN_ID;
        break;
      case "balance_transfer":
        formId = process.env.FORM_BALANCE_TRANSFER_ID;
        break;
      // case "commercial_loan":
      //   formId = process.env.FORM_COMMERCIAL_LOAN_ID;
      //   break;
      // case "mortgage":
      //   formId = process.env.FORM_MORTGAGE_ID;
      //   break;
    }
    let companyIds = []
    if (formId) {
      const { data } = await this.$axios.get('/survey/getByCode/' + formId)
      companyIds = data?.data?.companyIdList || []
    }
    if (companyIds.length) {
      const { data } = await this.$axios.get('/company/getCompaniesByType?type=mortgage,loan')
      data.data.forEach((item) => {
        if (companyIds.indexOf(item.id) > -1) {
          vendorList.push(item)
        }
      })
      commit('setVendors', vendorList)
    } else {
      commit('setVendors', [])
    }
  },
  fetchAppliedProductInfo: async function ({ commit }, hkid) {
    try {
      const { data } = await this.$axios.get('/checkId/getAppliedProductInfo?hkid=' + hkid, { headers: commonFunc.getBearTokenHeader("vendor_token") })
      commit('setAppliedProductInfo', data)
    } catch (e) {
      console.error(e);
    }
  },
  fetchSurveyCode: async function ({ commit }, surveyId) {
    // const { data } = await this.$axios.get('/company/getCompanyById/' + companyId)
    // const surveyId = data?.data?.surveyId
    if (surveyId) {
      const { data } = await this.$axios.get('/survey/getById/' + surveyId)
      commit('setSurveyCode', data?.data)
    }
  },
  approve: async function ({ commit }, params) {
    const hkid = params.hkid
    delete params.hkid
    try {
      const { data } = await this.$axios.post('/checkId/submit/' + hkid, params, { headers: commonFunc.getBearTokenHeader("vendor_token") })
      commit('setApproveResult', data)
    } catch (e) {
      console.error(e);
    }
  },
  secondApprove: async function ({ commit }, params) {
    const hkid = params.hkid
    delete params.hkid
    try {
      const { data } = await this.$axios.post('/checkId/submit/second/' + hkid, params, { headers: commonFunc.getBearTokenHeader("vendor_token") })
      commit('setSecondApproveResult', data)
    } catch (e) {
      console.error(e);
    }
  },
  interview: async function ({ commit }, params) {
    const hkid = params.hkid
    delete params.hkid
    try {
      const { data } = await this.$axios.post('/checkId/submit/interviewResult/' + hkid, params, { headers: commonFunc.getBearTokenHeader("vendor_token") })
      commit('setInterviewResult', data)
    } catch (e) {
      console.error(e);
    }
  },
  comment: async function ({ commit }, params) {
    const hkid = params.hkid
    delete params.hkid
    try {
      const { data } = await this.$axios.post('/checkId/addcomment/' + hkid, params, { headers: commonFunc.getBearTokenHeader("vendor_token") })
      commit('setCommentResult', data)
    } catch (e) {
      console.error(e);
    }
  },
  auth: async function ({ commit }, params) {
    const { data } = await this.$axios.post('/checkId/login', params)
    commit('setAuth', data)
  },
  confirmContactTime: async function ({ commit }, params) {
    const hkid = params.hkid
    const companyId = params.companyId
    try {
      const { data } = await this.$axios.post('/checkId/submit/second/confirmTime/' + hkid + '/' + companyId, { headers: commonFunc.getBearTokenHeader("vendor_token") })
      commit('setConfirmContactTime', data)
    } catch (e) {
      console.error(e);
    }
  },
  changeContactTime: async function ({ commit }, params) {
    const path = params.by === 'borrower' ? 'updateContactTimeByBorrower' : 'updateInterviewTimeByLender'
    delete params.by
    try {
      const { data } = await this.$axios.post('/checkId/' + path, params, { headers: commonFunc.getBearTokenHeader("vendor_token") })
      commit('setConfirmContactTime', data)
    } catch (e) {
      console.error(e);
    }
  },
  cancel: async function ({ commit }, params) {
    const path = params.by === 'borrower' ? 'cancelByBorrower' : 'cancelByVendor'
    delete params.by
    try {
      const { data } = await this.$axios.post('/checkId/submit/second/' + path, params, { headers: commonFunc.getBearTokenHeader("vendor_token") })
      commit('setCancelResult', data)
    } catch (e) {
      console.error(e);
    }
  },
  verifiyMobile: async function ({ commit }, params) {
    const { data } = await this.$axios.post('/checkId/isSmsVerified', params, { headers: commonFunc.getBearTokenHeader("vendor_token") })
    commit('setMobileVerify', data)
  },
  verifiyEmail: async function ({ commit }, params) {
    const { data } = await this.$axios.post('/checkId/isEmailVerified', params, { headers: commonFunc.getBearTokenHeader("vendor_token") })
    commit('setEmailVerify', data)
  },
}
