export const state = () => ({
  token: null
})
export const mutation = {
  setToken(state, token) {
    state.token = token
  },
  clearToken(state) {
    state.token = null
  }
}
export const actions = {
  nuxtServerInit({ dispatch }, { req }) {
    const initData = [
      dispatch('layout/fetchSeo', req.originalUrl),
      dispatch('layout/fetchMenu')
    ]
    return Promise.all(initData);
  }
}