import Vue from 'vue'
import us from '../lang/en-US.json'
import hk from '../lang/zh-HK.json'

const mixin = {
  methods: {
    t(key, template, defaultLocale = undefined) {
      let returnVal = key
      let locale = this.getLocale()
      if (defaultLocale !== undefined) {
        locale = defaultLocale
      }
      const acceptLang = {
        en: 'en',
        zhhk: 'zh'
      }
      const file = {
        en: us,
        zh: hk
      }
      const revampLang = acceptLang[locale] || 'zh'
      const langFile = file[revampLang]
      const keyArr = String(key).split('.')
      if (keyArr.length === 1) {
        // Not Array
        returnVal = langFile[key] || key
      } else {
        // Loop Array
        try {
          let dummy = langFile
          for (const idx in keyArr) {
            dummy = dummy[keyArr[idx]]
          }
          returnVal = dummy || key
        } catch (err) {
          console.log('Lang Error :', { key, err })
        }
      }
      let result = returnVal
      if (typeof template === 'object') {
        result = this._stringTemplate(returnVal, template)
      }
      return result
    },
    getLocale() {
      let locale = 'zh'
      const acceptLangs = ['zh', 'en']
      if (acceptLangs.includes(this.$route.query.lang)) {
        locale = this.$route.query.lang
        // this.$cookies.set('locale', locale)
        return locale
      }
      const parseCookie = str =>
        str
          .split(';')
          .map(v => v.split('='))
          .reduce((acc, v) => {
            if (v[0] !== undefined && v[1] !== undefined) {
              acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(
                v[1].trim()
              )
            }
            return acc
          }, {})
      if (this.$cookies === undefined && Vue.prototype.$nuxt) {
        locale = Vue.prototype.$nuxt.$cookies.get('locale')
      } else if (process.browser) {
        locale = parseCookie(document.cookie).locale
      } else {
        locale = this.$cookies.get('locale', 'zh')
      }
      return locale === undefined ? 'zh' : locale
    },
    _stringTemplate(template, variables) {
      return template.replace(new RegExp('{([^{]+)}', 'g'), function (
        _unused,
        varName
      ) {
        // eslint-disable-line
        return variables[varName] || `{${varName}}`
      })
    }
  }
}

Vue.mixin(mixin)

export { mixin }
