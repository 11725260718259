<template>
  <header>
    <div class="container-fluid">
      <div class="relative">
        <div class="header-logo col-lg-2">
          <a href="/" class="d-inline-flex">
            <img :src="logoUrl" alt="Money Plaza" />
          </a>
        </div>
        <no-ssr>
          <a
            v-if="isMobile"
            :href="appSource"
            target="_blank"
            class="btn btn-primary btn-download"
            >{{ t("menu.download") }}</a
          >
        </no-ssr>
        <div v-click-outside="clickOutside" class="nav">
          <i @click="toggleMenu(true)" />
          <ul>
            <a href="/" class="mobile-only">
              <img :src="logoUrl" alt="Money Plaza" />
            </a>
            <div
              class="main-menu"
              :class="'items-' + ($store.state.layout.menu.length - 1)"
            >
              <router-link
                v-for="(menu, index) in $store.state.layout.menu"
                v-if="menu.path !== '/blog'"
                :key="index"
                :to="menu.path"
              >
                <li @click="toggleMenu(false)">
                  {{ t("menu." + menu.path.replace("/", "")) }}
                </li>
              </router-link>
            </div>
            <router-link to="/blog">
              <li @click="toggleMenu(false)">
                {{ t("menu.blog") }}
              </li>
            </router-link>
            <router-link to="/query">
              <li @click="toggleMenu(false)">
                {{ t("common.qa") }}
              </li>
            </router-link>
            <div class="right-button">
              <client-only>
                <a
                  v-if="!isAuth"
                  @click="showFormModal"
                  href="javascript:void(0)"
                  class="apply-form-btn"
                >
                  {{ t("menu.form") }}
                </a>
                <div v-if="isAuth" class="dropdown">
                  <button
                    id="dropdownMenuButton"
                    class="btn dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>Hi! {{ username }}</span>
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <h5>{{ t("profile.info") }}</h5>
                    <nuxt-link class="dropdown-item" to="/profile" exact>
                      {{ t("profile.basic") }}
                    </nuxt-link>
                    <nuxt-link class="dropdown-item" to="/profile/contact">
                      {{ t("profile.contact") }}
                    </nuxt-link>
                    <nuxt-link class="dropdown-item" to="/profile/requirement">
                      {{ t("profile.requirement") }}
                    </nuxt-link>
                    <nuxt-link class="dropdown-item" to="/profile/loan_status">
                      {{ t("profile.loan_status") }}
                    </nuxt-link>
                    <nuxt-link class="dropdown-item" to="/profile/reward">
                      {{ t("profile.reward") }}
                    </nuxt-link>
                    <nuxt-link
                      class="dropdown-item"
                      to="/profile/resetpassword"
                    >
                      {{ t("profile.change_password") }}
                    </nuxt-link>
                    <div class="dropdown-divider" />
                    <nuxt-link class="dropdown-item" to="/logout">
                      {{ t("login.logout") }}
                    </nuxt-link>
                  </div>
                </div>
                <nuxt-link v-else to="/login" class="login-btn">
                  <button class="btn btn-secondary">
                    {{ t("menu.login") }}
                  </button>
                </nuxt-link>
              </client-only>
            </div>
            <div class="btn-group globe-list">
              <button
                type="button"
                class="btn dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fa fa-globe" />
                <span>{{ locale }}</span>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <button @click="setLocale('zh')" class="dropdown-item">
                  繁體
                </button>
                <button @click="setLocale('en')" class="dropdown-item">
                  English
                </button>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
    <ApplyModal />
  </header>
</template>
<script>
import ApplyModal from "@/components/form/ApplyModal.vue";
import commonFunc from "~/assets/js/commonHelper.js";
export default {
  nam: "Header",
  components: {
    ApplyModal,
  },
  data() {
    return {
      logoUrl: "/img/logo.svg",
      navShow: false,
      isMobile: false,
      iosAppStoreLink: "https://apps.apple.com/us/app/money-plaza/id1482745028",
      androidAppStoreLink:
        "https://play.google.com/store/apps/details?id=hk.com.moneyplaza.app",
      isAuth: false,
      username: "",
    };
  },
  computed: {
    locale() {
      const lang = this.$cookies.get("locale") === "en" ? "English" : "繁體";
      return lang;
    },
    appSource() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        return this.iosAppStoreLink;
      } else if (/(Android)/i.test(navigator.userAgent)) {
        return this.androidAppStoreLink;
      }
      return "#";
    },
  },
  mounted() {
    this.isAuth = commonFunc.isAuth();
    this.username = commonFunc.getUsername();
    this.isMobile = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
  },
  methods: {
    toggleMenu: function (on) {
      this.menuOn = on;
      if (on) {
        document.querySelector("body").classList.add("menu-on");
      } else {
        document.querySelector("body").classList.remove("menu-on");
      }
    },
    clickOutside: function () {
      this.toggleMenu(false);
    },
    showFormModal() {
      this.toggleMenu(false);
      $("#apply-modal #service-txt").val("").attr("readonly", false);
      $("#apply-modal").modal("show");
    },
    setLocale(locale) {
      this.$cookies.set("locale", locale);
      const href = new URL(window.location.href);
      href.searchParams.set("lang", locale);
      window.location.href = href.toString();
    },
  },
};
</script>