<template>
  <div class="apps">
    <li v-for="(app, index) in apps" :key="index">
      <a :href="app.link" target="blank">
        <img :src="app.imgUrl" >
      </a>
    </li>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      apps: [
        {
          name: "Play Store",
          link: "https://play.google.com/store/apps/details?id=hk.com.moneyplaza.app",
          imgUrl: "/img/play-store.svg",
          android: true,
        },
        {
          name: "Apple Store",
          link: "https://apps.apple.com/us/app/money-plaza/id1482745028",
          imgUrl: "/img/apple-store.svg",
          android: false,
        },
      ],
    };
  },
};
</script>
