<template>
  <div id="loading-page" class="container">
    <Nuxt />
  </div>
</template>

<script>
export default {
}
</script>
<style lang="scss">
</style>
