export default {
  /*
  ** Headers of the page
  */
  head: {
    titleTemplate: '%s',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1,maximum-scale=1.0, user-scalable=no' },
      { name: 'referrer', content: 'no-referrer' },
      { hid: 'keywords', name: 'keywords', content: '貸款比較, 按揭比較, 信用卡比較' },
      { hid: 'description', name: 'description', content: '正規的金融機構都需要有渠道去找尋有需要的客戶， 我們便創造了一個全新的最方便及安全的MONEY PLAZA金融比較平台，使消費者在足不出戶為消費者比較每一間金融機構的產品 ' }
    ],
    link: [
      { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
    ]
  }
}
