<template>
  <section>
    <div
      id="apply-modal"
      class="modal apply-modal fade"
      tabindex="-1"
      aria-labelledby="applyModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable modal-autoheight">
        <button
          type="button"
          class="modal-btn-close"
          data-dismiss="modal"
        >
          <img src="/img/icons/icon-close.png" alt="close">
        </button>
        <div class="modal-content">
           <button
            data-dismiss="modal"
            type="button"
            class="modal-btn-close"
          />
          <div class="modal-body">
            <div class="apply-modal-details-content">
              <h4>{{ t('contact.form_title') }}</h4>
              <label>{{ t('contact.require_info') }}</label><br>
              <form id="award-form" @submit.prevent="formSubmit" autocomplete="off">
                <label>{{ t('contact.email') }}</label>
                <input
                  ref="email"
                  :fieldTitle="t('contact.email',{},'zh')"
                  class="form-control"
                  type="email"
                  maxlength="200"
                  autocomplete="off"
                  fieldname="email"
                  fieldType="unique"
                  required
                >
                <label>{{ t('contact.eng_name') }}</label>
                <input
                  ref="name"
                  :fieldTitle="t('contact.eng_name',{},'zh')"
                  class="form-control"
                  type="input"
                  maxlength="200"
                  fieldname="fullname"
                  fieldType="text"
                  required
                >
                <label>{{ t('contact.ref') }}</label>
                <input
                  ref="identity"
                  :fieldTitle="t('contact.ref',{},'zh')"
                  class="form-control"
                  type="input"
                  fieldname="referenceNo"
                  fieldType="text"
                  maxlength="16"
                >
                <label>{{ t('contact.phone') }}</label>
                <input
                  ref="phone"
                  :fieldTitle="t('contact.phone',{},'zh')"
                  class="form-control"
                  type="phone"
                  fieldname="mobile"
                  fieldType="mobile"
                  maxlength="14"
                  required
                >
                <label>{{ t('contact.org_service') }}</label>
                <input
                  id="service-txt"
                  ref="service"
                  :fieldTitle="t('contact.org_service',{},'zh')"
                  fieldname="organization"
                  fieldType="text"
                  role="presentation"
                  class="form-control"
                  type="text"
                  autoComplete="off"
                  maxlength="200"
                  required
                >
                <div class="text-center pt-3">
                  <button
                    :class="loading ? 'disabled' : ''"
                    class="
                      rounded-pill
                      w-25
                      text-center
                      bg-teal
                      text-white
                      border-0
                      lh-xs-30
                      fs-xs-20
                      btn-secondary
                    "
                    type="submit"
                  >
                    <span v-if="loading">{{ t('contact.sending') }}</span>
                    <span v-else>{{ t('contact.done') }}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import commonFunc from '../../assets/js/commonHelper';
export default {
  name: "ApplyModal",
  data() {
    return {
      loading: false,
      success: false,
      failed: false,
    };
  },
  async mounted() {
    document.querySelector("#apply-modal form").reset();
    this.loading = false;
    try {
      await this.$recaptcha.init()
    } catch (e) {
      console.error(e);
    }
  },
  beforeDestroy() {
    this.$recaptcha.destroy();
  },
  methods: {
    formSubmit: function (e) {
      if (this.loading) {
        e.preventDefault();
        return false
      }
      const vm = this
      this.loading = true
      commonFunc.surveyFormSubmit('award-form', "rewardForm", function() {
        commonFunc.toast(vm.t('contact.success'), 'success')
        vm.loading = false;
      }, function() {
        commonFunc.toast(vm.t('contact.fail'), 'danger')
        vm.loading = false;
      }, this.$recaptcha)
      $("#apply-modal").modal('hide')
      e.preventDefault();
      return false;
    },
  },
};
</script>
