// import commonFunc from '../assets/js/commonHelper';
export const state = () => ({
  menu: []
})
const seo = {}
export const mutations = {
  setMenu(state, items) {
    state.menu = items
  },
  setBanner(state, items) {
    state.banner = items
  },
  setSeo(state, items) {
    const seoData = seo
    state.seo = seo
    if (items !== undefined && items.length > 0) {
      const item = items[0]
      if (item.title !== undefined) {
        seoData.title = item.title
      }
      if (item.keyword !== undefined) {
        seoData.keyword = item.keyword
      }
      if (item.description !== undefined) {
        seoData.desc = item.description
      }
    }
    state.seo = seoData
  }
}
export const actions = {
  fetchMenu: async function ({ commit }) {
    const { data } = await this.$axios.get('/menu/getTopMenus')
    commit('setMenu', data.data)
  },
  fetchBanner: async function ({ commit }, payload) {
    const type = payload.type || 'loan'
    const { data } = await this.$axios.get('/banner/getBannersByType?type=' + type)
    commit('setBanner', data.data)
  },
  fetchSeo: async function ({ commit }, params) {
    let title = false
    let path = ''
    if (typeof params === 'object' && params !== null) {
      path = params.path
      title = params.title
    } else {
      path = params
    }
    const param = {
      path: decodeURIComponent(path)
    }
    const { data } = await this.$axios.post('/seo/getByPath', param)
    let result = data.data
    if (title && result.length === 0) {
      result = [{ title: title }]
    }
    commit('setSeo', result)
  },
}
